<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M6.81901 3.0705C7.08167 3.03167 7.35263 3.01084 7.6317 3.01084C10.931 3.01084 13.096 5.92227 13.8233 7.07394C13.9114 7.21333 13.9554 7.28303 13.98 7.39053C13.9985 7.47126 13.9985 7.59862 13.98 7.67935C13.9553 7.78685 13.911 7.85701 13.8224 7.99732C13.6286 8.30404 13.3331 8.73508 12.9417 9.20258M4.22182 4.11925C2.8245 5.06714 1.87588 6.38406 1.4407 7.07295C1.35228 7.21292 1.30807 7.28291 1.28342 7.3904C1.26491 7.47113 1.2649 7.59848 1.2834 7.67921C1.30804 7.78671 1.35205 7.8564 1.44008 7.99578C2.16741 9.14745 4.3324 12.0589 7.6317 12.0589C8.96203 12.0589 10.1079 11.5855 11.0495 10.9451M1.81511 1.71826L13.4483 13.3515M6.26072 6.16387C5.90985 6.51474 5.69284 6.99946 5.69284 7.53486C5.69284 8.60567 6.5609 9.47373 7.6317 9.47373C8.16711 9.47373 8.65182 9.25671 9.00269 8.90584" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconEyeCrossed"
}
</script>
